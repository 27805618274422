.feedback-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin: 10 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.feedback-label {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}

.feedback-input {
  width: 60%;
  padding: 10px;
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.feedback-submit {
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.feedback-submit:hover {
  background-color: #0056b3;
}

.feedback-form {
  display: flex;
  flex-direction: column;
}

.feedback-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.feedback-input {
  margin-left: auto;
}

textarea.feedback-input {
  height: 100px; /* この値を調整してテキストエリアの高さを変更します */
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 1px;
  margin-bottom: 5px;
}