.chat-quick-reply-container {
  display: flex;
  justify-content: center;
}

.feedback-button {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  background-color: #868DA0; /* まろやかなグレー色 */
  border-radius: 30px; /* 角が丸い枠 */
  padding: 10px;
  margin: 5px;
}

.feedback-content {
  display: flex;
  align-items: center;
}